<template>
  <v-app>
    <v-container>
      <v-card class="px-10 py-10">
        <v-row no-gutters>
          <v-col lg="8" md="8" sm="8" cols="8">
            <h5 class="font-weight-bold text-h5 text-typo mb-0">
              Үнэлгээ 2 -ийн дүн шинжилгээ
            </h5>
            <p class="text-sm text-body mb-0">
              Анги, эсвэл бүлгээ сонгож дүн шинжилгээ харах боломжтой.
            </p>
            <!-- <p class="text-sm mb-4 red--text">Тайлбар:</p> -->
          </v-col>

          <v-col cols="4" style="background-color: yellow">
            <v-select
              :items="[
                {
                  yearId: 1,
                  name: preText + ' xичээлийн жил, 1-р xагас жил',
                },
                {
                  yearId: 2,
                  name: preText + ' xичээлийн жил, 2-р xагас жил',
                },
              ]"
              v-model="selectedHalfYear"
              label="Улирал"
              return-object
              item-text="name"
              item-value="yearId"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-n1" justify="space-between">
          <v-col cols="6">
            <v-row>
              <!-- <v-col cols="4" v-if="departments" class="px-4">
                <v-select
                  :items="[
                    { name: 'Бага анги', level: 1 },
                    { name: 'Дунд анги', level: 2 },
                    { name: 'Ахлаx анги', level: 3 },
                  ]"
                  item-value="level"
                  item-text="name"
                  return-object
                  label="Түвшин сонгоx"
                  v-model="selectedLevel"
                  clearable
                ></v-select>
              </v-col> -->
              <v-col cols="4" v-if="departments" class="px-4">
                <v-select
                  :items="departments"
                  item-value="id"
                  item-text="name"
                  return-object
                  label="Анги сонгох"
                  v-model="selectedDepartment"
                  clearable
                ></v-select>
              </v-col>
              <v-col
                class="px-4"
                cols="4"
                v-if="selectedDepartment && selectedDepartment.classGroups"
              >
                <v-select
                  :items="selectedDepartment.classGroups"
                  item-value="id"
                  item-text="STUDENT_GROUP_NAME"
                  return-object
                  label="Бүлэг сонгох"
                  v-model="selectedClassGroup"
                  clearable
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="my-3" cols="auto">
            <v-btn class="bg-gradient-info white--text" @click="readCourses()"
              >Дүн шинжилгээ хараx</v-btn
            >
          </v-col>
        </v-row>
        <!-- {{ totalChecked }} -->
        <!-- <v-btn @click="findNotAnalyzedDocuments()">find all documents</v-btn> -->
        <!-- <v-btn @click="logRefs()">print all documents</v-btn> -->
        <!-- <v-btn @click="saveAllUnelgee2Analytics()">update all</v-btn> -->

        <v-data-table
          class="scoreTables"
          v-if="loading == false && courses && courses.length > 0"
          :items="courses"
          hide-default-footer
          hide-default-header
          :items-per-page="-1"
        >
          <template slot="header">
            <tr style="font-size: 13px">
              <th class="text-start font-weight-normal px-1">No</th>
              <th class="text-start font-weight-normal pl-1">Хичээл</th>
              <th class="pl-0" style="width: 50%">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="text-center font-weight-normal"
                    style="border-bottom: 2px solid #dddddd"
                    >Түвшин</v-col
                  >
                  <v-col
                    class="text-center font-weight-normal"
                    style="border-right: 2px solid #dddddd"
                    >I</v-col
                  >
                  <v-col
                    class="text-center font-weight-normal"
                    style="border-right: 2px solid #dddddd"
                    >II</v-col
                  >
                  <v-col
                    style="border-right: 2px solid #dddddd"
                    class="text-center font-weight-normal"
                    >III</v-col
                  >
                  <v-col
                    style="border-right: 2px solid #dddddd"
                    class="text-center font-weight-normal"
                    >IV</v-col
                  >
                  <v-col
                    style="border-right: 2px solid #dddddd"
                    class="text-center font-weight-normal"
                    >V</v-col
                  >
                  <v-col
                    style="border-right: 2px solid #dddddd"
                    class="text-center font-weight-normal"
                    >VI</v-col
                  >
                  <v-col
                    style="border-right: 2px solid #dddddd"
                    class="text-center font-weight-normal"
                    >VII</v-col
                  >
                  <v-col class="text-center font-weight-normal">VIII</v-col>
                </v-row>
              </th>
            </tr>
          </template>
          <template slot="item" slot-scope="props">
            <tr>
              <td class="text-center" style="width: 1%">
                {{ props.index + 1 }}
              </td>
              <td class="pl-1">
                {{ props.item.COURSE_NAME }}
              </td>
              <td class="px-0">
                <v-row no-gutters>
                  <v-col
                    @click="getScoreStudents(props.item, 1)"
                    class="text-center"
                    style="border-right: 2px solid #dddddd"
                    :style="
                      props.item.scoreAnalysis['_I'] > 0
                        ? 'background:#C51162; color:#fff'
                        : ''
                    "
                    >{{ props.item.scoreAnalysis["_I"] }}</v-col
                  >
                  <v-col
                    @click="getScoreStudents(props.item, 2)"
                    class="text-center"
                    style="border-right: 2px solid #dddddd"
                    :style="
                      props.item.scoreAnalysis['_II'] > 0
                        ? 'background:#F50057; color:#fff'
                        : ''
                    "
                  >
                    {{ props.item.scoreAnalysis["_II"] }}
                  </v-col>
                  <v-col
                    @click="getScoreStudents(props.item, 3)"
                    class="text-center"
                    style="border-right: 2px solid #dddddd"
                    :style="
                      props.item.scoreAnalysis['_III'] > 0
                        ? 'background:#FF4081; color:#fff'
                        : ''
                    "
                  >
                    {{ props.item.scoreAnalysis["_III"] }}
                  </v-col>
                  <v-col
                    @click="getScoreStudents(props.item, 4)"
                    class="text-center"
                    style="border-right: 2px solid #dddddd"
                    :style="
                      props.item.scoreAnalysis['_IV'] > 0
                        ? 'background:#FF80AB; color:#fff'
                        : ''
                    "
                  >
                    {{ props.item.scoreAnalysis["_IV"] }}
                  </v-col>
                  <v-col
                    @click="getScoreStudents(props.item, 5)"
                    class="text-center"
                    style="border-right: 2px solid #dddddd"
                  >
                    {{ props.item.scoreAnalysis["_V"] }}
                  </v-col>
                  <v-col
                    @click="getScoreStudents(props.item, 6)"
                    class="text-center"
                    style="border-right: 2px solid #dddddd"
                  >
                    {{ props.item.scoreAnalysis["_VI"] }}
                  </v-col>
                  <v-col
                    @click="getScoreStudents(props.item, 7)"
                    class="text-center"
                    style="border-right: 2px solid #dddddd"
                  >
                    {{ props.item.scoreAnalysis["_VII"] }}
                  </v-col>
                  <v-col
                    @click="getScoreStudents(props.item, 8)"
                    class="text-center"
                  >
                    {{ props.item.scoreAnalysis["_VIII"] }}
                  </v-col>
                </v-row>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-progress-linear
          v-else-if="loading == true"
          indeterminate
          color="red"
        ></v-progress-linear>
        <div class="text-center mt-16" v-else>
          <h3
            v-if="selectedDepartment || selectedClassGroup"
            class="text-body px-10 mx-10"
          >
            Бүлгээр дүн шинжилгээ харах бол харгалзах бүлгээ сонгож
            <p class="blue--text text-uppercase my-2">"дүн шинжилгээ харах"</p>
            товчийг дарна уу!
          </h3>
          <h3
            v-else-if="!selectedDepartment && !selectedClassGroup"
            class="text-body px-10 mx-10"
          >
            Ангиар дүн шинжилгээ харах бол харгалзах ангиа сонгож
            <p class="blue--text text-uppercase my-2">"дүн шинжилгээ харах"</p>
            товчийг дарна уу!
          </h3>
        </div>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
const fb = require("@/firebaseConfig.js"); // eslint-disable-line

export default {
  data() {
    return {
      selectedHalfYear: null,
      preText: null,
      departments: null,
      selectedLevel: null,
      selectedDepartment: null,
      selectedClassGroup: null,
      courses: null,
      loading: false,
      loading2: false,
      analyzingRefPaths: [],
      totalChecked: 0,
      lastDoc: null,
    };
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  watch: {
    selectedHalfYear(val) {
      this.$store.state.ClassSelectedSelectedHalfYearId = val.yearId;
      val.name =
        this.preText + " xичээлийн жил, " + val.yearId + "-р xагас жил";
      if (this.userData.school) {
        this.readCourses();
      }
    },
    selectedDepartment(val) {
      this.selectedClassGroup = null;
      if (val == null) {
        this.courses = null;
      }
    },
    selectedClassGroup(val) {
      if (val == null) {
        this.courses = null;
      }
    },
  },
  created() {
    this.preText =
      this.userData.school.currentYear-1 +
      "-" +
      (this.userData.school.currentYear);
    this.selectedHalfYear = {
      yearId: this.$store.state.ClassSelectedSelectedHalfYearId,
      name:
        this.preText +
        " xичээлийн жил, " +
        this.$store.state.ClassSelectedSelectedHalfYearId +
        "-р xагас жил",
    };
    this.userData.school.ref
      .collection("departments-" + this.userData.school.currentYear)
      .orderBy("index", "desc")
      .get()
      .then((docs) => {
        this.departments = [];
        docs.forEach(async (doc) => {
          let dep = doc.data();
          dep.ref = doc.ref;
          dep.id = doc.id;

          dep.classGroups = null;
          await dep.ref
            .collection("programs")
            .orderBy("STUDENT_GROUP_NAME", "asc")
            .get()
            .then((docs) => {
              dep.classGroups = [];
              docs.forEach((doc) => {
                let classGroup = doc.data();
                classGroup.ref = doc.ref;
                classGroup.id = doc.id;

                dep.classGroups.push(classGroup);
              });
            });

          this.departments.push(dep);
        });
      });
  },
  methods: {
    getScoreStudents(course, scoreLevel) {
      console.log(course, scoreLevel);
      if (this.selectedDepartment && this.selectedClassGroup) {
        this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-scores"
          )
          .doc(
            this.selectedHalfYear.yearId > 1
              ? this.selectedClassGroup.STUDENT_GROUP_ID +
                  "-" +
                  course.COURSE_ID +
                  "-2"
              : this.selectedClassGroup.STUDENT_GROUP_ID +
                  "-" +
                  course.COURSE_ID
          )
          .get()
          .then((doc) => {
            this.loading2 = true;
            this.students = [];
            let unelgee2Doc = doc.data();
            unelgee2Doc.ref = doc.ref;
            unelgee2Doc.id = doc.id;

            unelgee2Doc.ref
              .collection("scores")
              .where("scoreLevelNumber", "==", scoreLevel)
              .where("approved", "==", true)
              .get()
              .then((docs) => {
                var loadingCounter = 0;
                docs.forEach((doc) => {
                  let studentScore = doc.data();
                  studentScore.id = doc.id;
                  studentScore.ref = doc.ref;

                  this.students.push(studentScore);
                  loadingCounter++;
                  if (loadingCounter == docs.size) {
                    console.log(this.students);
                    this.loading2 = false;
                  }
                });
              });
          });
      } else {
        this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-scores"
          )
          .where("_COURSE_ID", "==", course.COURSE_ID)
          .get()
          .then((docs) => {
            var loadingCounter = 0;
            this.loading2 = true;
            this.students = [];
            docs.forEach(async (doc) => {
              let unelgee2Doc = doc.data();
              unelgee2Doc.ref = doc.ref;
              unelgee2Doc.id = doc.id;

              await unelgee2Doc.ref
                .collection("scores")
                .where("scoreLevelNumber", "==", scoreLevel)
                .where("approved", "==", true)
                .get()
                .then((docs) => {
                  var loadingCounter2 = 0;
                  docs.forEach((doc) => {
                    let studentScore = doc.data();
                    studentScore.id = doc.id;
                    studentScore.ref = doc.ref;

                    this.students.push(studentScore);
                    loadingCounter2++;
                    if (loadingCounter2 == docs.size) {
                      loadingCounter++;
                    }
                  });
                });

              if (loadingCounter == docs.size) {
                console.log(this.students);
                this.loading2 = false;
              }
            });
          });
      }
    },
    logRefs() {
      console.log(this.analyzingRefPaths);
    },
    //do not delete these functions check for not analyzed unelgee2 scores
    // findNotAnalyzedDocuments() {
    //   var go2 = true;
    //   if (go2) {
    //     var query;
    //     console.log(this.lastDoc);
    //     if (this.lastDoc == null) {
    //       query = fb.db.collectionGroup("unelgee2-2023-scores").limit(500);
    //     } else {
    //       query = fb.db
    //         .collectionGroup("unelgee2-2023-scores")
    //         .startAfter(this.lastDoc)
    //         .limit(500);
    //     }

    //     query.get().then((docs) => {
    //       var counter = 0;
    //       var analyzedCounter = 0;
    //       var analyzedNotEmptyCounter = 0;
    //       var notEmptyCounter = 0;
    //       var emptyCounter = 0;
    //       var totalCollectionCounter = docs.size;
    //       var notAnalyzedRefs = [];
    //       var needToBeAnalyzedCounter = 0;
    //       this.lastDoc = docs.docs[docs.size - 1];
    //       console.log("found: " + docs.size);
    //       this.totalChecked = this.totalChecked + docs.size;
    //       docs.forEach(async (doc) => {
    //         var analyzed;
    //         if (doc.data()["_I"] != undefined) {
    //           analyzedCounter++;
    //           analyzed = true;
    //         } else {
    //           analyzed = false;
    //         }
    //         await doc.ref
    //           .collection("scores")
    //           .where("approved", "==", true)
    //           .get()
    //           .then((docs) => {
    //             if (docs.size > 0) {
    //               if (analyzed == true) {
    //                 analyzedNotEmptyCounter++;
    //               } else {
    //                 notAnalyzedRefs.push(doc.ref.path);
    //                 this.analyzingRefPaths.push(doc.ref.path);
    //                 needToBeAnalyzedCounter++;
    //               }
    //               notEmptyCounter++;
    //             } else {
    //               emptyCounter++;
    //             }
    //             counter++;
    //           });

    //         console.log(
    //           "working on: " + counter + "/" + totalCollectionCounter
    //         );
    //         if (counter == totalCollectionCounter) {
    //           setTimeout(() => {
    //             console.log(
    //               "amount of unelgee documents: " + totalCollectionCounter
    //             );
    //             console.log(
    //               "amount of analyzed unelgee documents: " + analyzedCounter
    //             );
    //             console.log(
    //               "amount of not empty unelgee documents: " + notEmptyCounter
    //             );
    //             console.log(
    //               "amount of analyzed not empty documents: " +
    //                 analyzedNotEmptyCounter
    //             );
    //             console.log(
    //               "IMPORTANT! amount of need to be analyzed documents: " +
    //                 needToBeAnalyzedCounter
    //             );
    //             console.log(notAnalyzedRefs);
    //             console.log(
    //               "amount of empty unelgee documents: " + emptyCounter
    //             );
    //           }, 5000);
    //         }
    //       });
    //     });
    //   }
    // },
    // saveAllUnelgee2Analytics() {
    //   var go = true;

    //   if (go) {
    //     console.log("unelgee2 collections: " + this.analyzingRefPaths.length);
    //     this.analyzingRefPaths.forEach((analyzingRefPath) => {
    //       fb.db
    //         .doc(analyzingRefPath)
    //         .get()
    //         .then(async (doc) => {
    //           let unelgee2Collection = doc.data();
    //           unelgee2Collection.ref = doc.ref;
    //           unelgee2Collection.id = doc.id;

    //           await unelgee2Collection.ref
    //             .collection("scores")
    //             .get()
    //             .then((docs) => {
    //               console.log(
    //                 "unelgee2 collection scores collections: " + docs.size
    //               );
    //               var allApproved = true;
    //               var allNotSent = true;
    //               var scoreLevelStrings = {
    //                 _I: 0,
    //                 _II: 0,
    //                 _III: 0,
    //                 _IV: 0,
    //                 _V: 0,
    //                 _VI: 0,
    //                 _VII: 0,
    //                 _VIII: 0,
    //               };
    //               var academicLevel;
    //               var courseId;
    //               var schoolId;

    //               docs.forEach(async (doc) => {
    //                 if (doc.data().approved == true) {
    //                   allNotSent = false;
    //                   let approvedScore = doc.data();
    //                   approvedScore.ref = doc.ref;
    //                   approvedScore.id = doc.id;
    //                   await fb.db
    //                     .collection("schools")
    //                     .doc(approvedScore.schoolId)
    //                     .collection("students-" + approvedScore.year)
    //                     .doc(String(approvedScore["PERSON_ID"]))
    //                     .get()
    //                     .then((doc) => {
    //                       let student = doc.data();
    //                       student.ref = doc.ref;
    //                       student.id = doc.id;
    //                       if (!academicLevel)
    //                         academicLevel = student.ACADEMIC_LEVEL;
    //                       courseId = approvedScore.courseInfo.COURSE_ID;
    //                       schoolId = approvedScore["schoolId"];
    //                       if (approvedScore.scoreLevelNumber == 1) {
    //                         scoreLevelStrings["_I"]++;
    //                       } else if (approvedScore.scoreLevelNumber == 2) {
    //                         scoreLevelStrings["_II"]++;
    //                       } else if (approvedScore.scoreLevelNumber == 3) {
    //                         scoreLevelStrings["_III"]++;
    //                       } else if (approvedScore.scoreLevelNumber == 4) {
    //                         scoreLevelStrings["_IV"]++;
    //                       } else if (approvedScore.scoreLevelNumber == 5) {
    //                         scoreLevelStrings["_V"]++;
    //                       } else if (approvedScore.scoreLevelNumber == 6) {
    //                         scoreLevelStrings["_VI"]++;
    //                       } else if (approvedScore.scoreLevelNumber == 7) {
    //                         scoreLevelStrings["_VII"]++;
    //                       } else if (approvedScore.scoreLevelNumber == 8) {
    //                         scoreLevelStrings["_VIII"]++;
    //                       }
    //                     });
    //                 } else {
    //                   allApproved = false;
    //                 }
    //               });
    //               setTimeout(() => {
    //                 var doUpdate = false;
    //                 var updateTMP = {
    //                   _confirmed100: allApproved,
    //                 };
    //                 if (unelgee2Collection["_ACADEMIC_LEVEL"] == undefined) {
    //                   updateTMP["_ACADEMIC_LEVEL"] = academicLevel;
    //                   doUpdate = true;
    //                 }
    //                 if (unelgee2Collection["_COURSE_ID"] == undefined) {
    //                   updateTMP["_COURSE_ID"] = courseId;
    //                   doUpdate = true;
    //                 }
    //                 if (unelgee2Collection["_schoolId"] == undefined) {
    //                   updateTMP["_schoolId"] = schoolId;
    //                   doUpdate = true;
    //                 }
    //                 if (
    //                   unelgee2Collection["_I"] == undefined ||
    //                   unelgee2Collection["_I"] != scoreLevelStrings["_I"]
    //                 ) {
    //                   updateTMP["_I"] = scoreLevelStrings["_I"];
    //                   doUpdate = true;
    //                 }
    //                 if (
    //                   unelgee2Collection["_II"] == undefined ||
    //                   unelgee2Collection["_II"] != scoreLevelStrings["_II"]
    //                 ) {
    //                   updateTMP["_II"] = scoreLevelStrings["_II"];
    //                   doUpdate = true;
    //                 }
    //                 if (
    //                   unelgee2Collection["_III"] == undefined ||
    //                   unelgee2Collection["_III"] != scoreLevelStrings["_III"]
    //                 ) {
    //                   updateTMP["_III"] = scoreLevelStrings["_III"];
    //                   doUpdate = true;
    //                 }
    //                 if (
    //                   unelgee2Collection["_IV"] == undefined ||
    //                   unelgee2Collection["_IV"] != scoreLevelStrings["_IV"]
    //                 ) {
    //                   updateTMP["_IV"] = scoreLevelStrings["_IV"];
    //                   doUpdate = true;
    //                 }
    //                 if (
    //                   unelgee2Collection["_V"] == undefined ||
    //                   unelgee2Collection["_V"] != scoreLevelStrings["_V"]
    //                 ) {
    //                   updateTMP["_V"] = scoreLevelStrings["_V"];
    //                   doUpdate = true;
    //                 }
    //                 if (
    //                   unelgee2Collection["_VI"] == undefined ||
    //                   unelgee2Collection["_VI"] != scoreLevelStrings["_VI"]
    //                 ) {
    //                   updateTMP["_VI"] = scoreLevelStrings["_VI"];
    //                   doUpdate = true;
    //                 }
    //                 if (
    //                   unelgee2Collection["_VII"] == undefined ||
    //                   unelgee2Collection["_VII"] != scoreLevelStrings["_VII"]
    //                 ) {
    //                   updateTMP["_VII"] = scoreLevelStrings["_VII"];
    //                   doUpdate = true;
    //                 }
    //                 if (
    //                   unelgee2Collection["_VIII"] == undefined ||
    //                   unelgee2Collection["_VIII"] != scoreLevelStrings["_VIII"]
    //                 ) {
    //                   updateTMP["_VIII"] = scoreLevelStrings["_VIII"];
    //                   doUpdate = true;
    //                 }

    //                 if (allNotSent) {
    //                   console.log("nothing is approved");
    //                 } else if (doUpdate) {
    //                   unelgee2Collection.ref.update(updateTMP).then(() => {
    //                     console.log(updateTMP, unelgee2Collection.ref.path);
    //                   });
    //                 } else {
    //                   console.log("already up to date");
    //                 }
    //               }, 10000);
    //             });
    //         });
    //     });
    //   }
    // },
    async getCourseLessonScores(course) {
      if (this.selectedClassGroup) {
        console.log(this.selectedClassGroup);
        await this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-scores"
          )
          .doc(
            this.selectedHalfYear.yearId > 1
              ? this.selectedClassGroup.STUDENT_GROUP_ID +
                  "-" +
                  course.COURSE_ID +
                  "-2"
              : this.selectedClassGroup.STUDENT_GROUP_ID +
                  "-" +
                  course.COURSE_ID
          )
          .get()
          .then((doc) => {
            if (doc.exists) {
              let scores = doc.data();
              scores.ref = doc.ref;
              scores.id = doc.id;
              course.scoreAnalysis = {
                _I: scores["_I"] ? scores["_I"] : 0,
                _II: scores["_II"] ? scores["_II"] : 0,
                _III: scores["_III"] ? scores["_III"] : 0,
                _IV: scores["_IV"] ? scores["_IV"] : 0,
                _V: scores["_V"] ? scores["_V"] : 0,
                _VI: scores["_VI"] ? scores["_VI"] : 0,
                _VII: scores["_VII"] ? scores["_VII"] : 0,
                _VIII: scores["_VIII"] ? scores["_VIII"] : 0,
              };
            } else {
              course.scoreAnalysis = {
                _I: 0,
                _II: 0,
                _III: 0,
                _IV: 0,
                _V: 0,
                _VI: 0,
                _VII: 0,
                _VIII: 0,
              };
            }
          });
      } else {
        // await fb.db
        //   .collectionGroup(
        //     "unelgee2-" + this.userData.school.currentYear + "-scores"
        //   )
        //   .where("_schoolId", "==", this.userData.school.id)
        await this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-scores"
          )
          .where("_ACADEMIC_LEVEL", "==", String(this.selectedDepartment.index))
          .where("_COURSE_ID", "==", course.COURSE_ID)
          .get()
          .then((docs) => {
            let scoreAnalysis = {
              _I: 0,
              _II: 0,
              _III: 0,
              _IV: 0,
              _V: 0,
              _VI: 0,
              _VII: 0,
              _VIII: 0,
            };
            docs.forEach((doc) => {
              let scores = doc.data();
              scores.ref = doc.ref;
              scores.id = doc.id;
              if (this.selectedHalfYear.yearId > 1) {
                if (scores.id.includes("-2")) {
                  scoreAnalysis["_I"] = scoreAnalysis["_I"] + scores["_I"];
                  scoreAnalysis["_II"] = scoreAnalysis["_II"] + scores["_II"];
                  scoreAnalysis["_III"] =
                    scoreAnalysis["_III"] + scores["_III"];
                  scoreAnalysis["_IV"] = scoreAnalysis["_IV"] + scores["_IV"];
                  scoreAnalysis["_V"] = scoreAnalysis["_V"] + scores["_V"];
                  scoreAnalysis["_VI"] = scoreAnalysis["_VI"] + scores["_VI"];
                  scoreAnalysis["_VII"] =
                    scoreAnalysis["_VII"] + scores["_VII"];
                  scoreAnalysis["_VIII"] =
                    scoreAnalysis["_VIII"] + scores["_VIII"];
                }
              } else {
                if (!scores.id.includes("-2")) {
                  scoreAnalysis["_I"] = scoreAnalysis["_I"] + scores["_I"];
                  scoreAnalysis["_II"] = scoreAnalysis["_II"] + scores["_II"];
                  scoreAnalysis["_III"] =
                    scoreAnalysis["_III"] + scores["_III"];
                  scoreAnalysis["_IV"] = scoreAnalysis["_IV"] + scores["_IV"];
                  scoreAnalysis["_V"] = scoreAnalysis["_V"] + scores["_V"];
                  scoreAnalysis["_VI"] = scoreAnalysis["_VI"] + scores["_VI"];
                  scoreAnalysis["_VII"] =
                    scoreAnalysis["_VII"] + scores["_VII"];
                  scoreAnalysis["_VIII"] =
                    scoreAnalysis["_VIII"] + scores["_VIII"];
                }
              }
            });

            course.scoreAnalysis = scoreAnalysis;
          });
      }
    },
    readCourses() {
      if (this.selectedDepartment && this.selectedClassGroup) {
        console.log(this.selectedClassGroup);
        this.selectedClassGroup.studyPLANRef
          .collection("courses")
          .get()
          .then((docs) => {
            this.loading = true;
            this.courses = [];
            docs.forEach(async (doc) => {
              let course = doc.data();
              course.ref = doc.ref;
              course.id = doc.id;
              await this.getCourseLessonScores(course);
              this.courses.push(course);
            });
            setTimeout(() => {
              this.loading = false;
            }, 1000);
          });
      } else if (this.selectedDepartment) {
        this.selectedDepartment.ref
          .collection("programs")
          .get()
          .then((docs) => {
            this.loading = true;
            this.courses = [];
            docs.forEach(async (doc) => {
              let classGroup = doc.data();
              classGroup.ref = doc.ref;
              classGroup.id = doc.id;
              await classGroup.studyProgramRef.get().then((doc) => {
                doc.ref
                  .collection("courses")
                  .get()
                  .then((docs) => {
                    docs.forEach(async (doc) => {
                      let course = doc.data();
                      course.ref = doc.ref;
                      course.id = doc.id;
                      await this.getCourseLessonScores(course);
                      if (
                        this.courses.find(
                          (ccourse) => ccourse.id == course.id
                        ) == undefined
                      ) {
                        this.courses.push(course);
                      }
                    });
                  });
              });
            });
            setTimeout(() => {
              this.loading = false;
              console.log(this.courses);
            }, 2000);
          });
      }
    },
  },
};
</script>
<style>
.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}

.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
</style>
